import { useParams } from "react-router-dom";
import { WaStatusChecker, buildDynamicTableData, getJobByJobId, getManyBenData } from "./Action/action";
import { WaMessageDetails } from "./WaMessageDetails";
import { useEffect, useState } from "react";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { getWaTemplates } from "../SendBulkWaMessage/Action/waConfig";
import { getSelectedTemplateDetails, handleBuildPayloadForWaMsg, updateWaJob } from "../SendBulkWaMessage/Action/actions";
import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import style from "./waMessage.module.css"
import {  getVariable } from "../../Helper/waTemplateVariables";
import { DynamicTable } from "../../GlobalComponent/CustomTable/DynamicTable";
import { BenFilterPopup } from "../../GlobalComponent/BenFIlterPopup/BenFilterPoup";
import { handleCloseModal } from "../BenAddressData/Action/actions";
import { FaTrashAlt } from 'react-icons/fa';
import { Box, Flex, Input, Select } from "@chakra-ui/react";


export enum MessageTo {
  All = "All",
  Subscriber = "Subscriber"
}
interface customInputInterface {
  customField: string,
  url: string
}
const emptyInput: customInputInterface = {
  customField: "",
  url: ""
}


export const WrapperWaMessage: React.FC = () => {
  const { jobId } = useParams();
  const [customInput, setCustomInput] = useState<customInputInterface>(emptyInput)
  //eslint-disable-next-line
  const [data, setData] = useState<any>([]);
  const [benFilter, setBenFilter] = useState<boolean>(false);
  const [benData, setBenData] = useState<any>([]);
  const [templateData, setTemplateData] = useState<any[]>([]);
  const [preSelectedTemplate, setPreSelectedTemplate] = useState<any>("");
  const [selectedTemplate, setSelectedTemplate] = useState<any>("");
  const [statusCheck, setStatusCheck] = useState(false);
  const [customeAdded, setCustomAdded] = useState(false)
  const [clearBtnStatus, setClearBtnStatus] = useState(false);
  const [tableDynamicData, setTableDynamicData] = useState<any>({
    header: [],
    benData: []
  });
  //eslint-disable-next-line
  const [isSelectTemp, setIsSelectTemp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getTemplate = async () => {
    try {
      const getData = await getWaTemplates();
      // console.log(getData);
      if (getData[0]?.configurations) {
        setTemplateData(getData[0].configurations);
      }
    } catch (e: any) {
      alert(
        e?.response?.message || "Some Technical Issue in getting Templates"
      );
    }
  };
  const handleCatchSelectedTemplate = (value: string) => {
    // console.log("value:-----", value);
    if (value) {
      getSelectedTemplateDetails({ value, setSelectedTemplate, templateData, jobId });
      setPreSelectedTemplate("")
    } else {
      setPreSelectedTemplate("");
      setSelectedTemplate("");
    }

  };

  useEffect(() => {
    getTemplate()
    if (preSelectedTemplate) {
      handleCatchSelectedTemplate(preSelectedTemplate)
    }
    //eslint-disable-next-line
  }, [preSelectedTemplate])

  const handleFilterModal = () => {
    setBenFilter(!benFilter);
  };
  const handleSendMsg = async (messageTo: string) => {
    if (!data.jobData) {
      alert("Please add jobData")
      return
    }
    if ((selectedTemplate["TEMPLATE_VARS"].length !== 0 || selectedTemplate["TEMPLATE_VARS"]) && !data?.customInput) {
      alert("please Add Custom Value and then Proceed")
      return

    }
    const res = await handleBuildPayloadForWaMsg({ setIsLoading, jobData: data.jobData, jobId });
    // console.log('res:', res);

    const status = WaStatusChecker(res?.data?.data);
    // console.log('status:', status);
    setStatusCheck(status);
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }

  const handleDeleteBen = async (benId: string) => {
    const res = data.jobData.filter((jobData: any) => jobData.benId !== benId);
    const payload = {
      jobData: res
    }
    const getLatestData = await updateWrapper({ jobId, payload });
    setBenData(getLatestData);
  }
  const deleteComponent = (benId: string) => {
    return <FaTrashAlt
      className={statusCheck ? style.deleteIconDisabled : style.deleteIcon}
      title={statusCheck ? "Cannot delete" : "Delete"}

      onClick={statusCheck ? () => { } : () => handleDeleteBen(benId)}
    />
  }


  //currently it is not using i need to stop these
  const handleClickDynamicTable = () => {

  }


  const handelClearBen = async () => {
    const getLatestData = await updateWrapper({ jobId, payload: { jobData: [] } });
    setData(getLatestData);
    setTableDynamicData({ header: [], benData: [] })
  }

  const addCustomUrl = async () => {
    let object = {}

    console.log(selectedTemplate["TEMPLATE_VARS"], selectedTemplate["TEMPLATE_HEADER_TYPE"])
    if (selectedTemplate["TEMPLATE_VARS"].length !== 0 && selectedTemplate["TEMPLATE_HEADER_TYPE"] && customInput["customField"] && customInput["url"]) {
      object = { customInput }

      await updateWaJob(jobId, object)
      // setCustomInput({ ...customInput, customField: response?.data?.customInput?.customField,url:response?.data?.customInput?.url })
      alert("Successfully Added Variable")
    } else if (selectedTemplate["TEMPLATE_VARS"].length !== 0 && customInput["customField"]) {

      object = { customInput: { customField: customInput["customField"] } }
      await updateWaJob(jobId, object)
      // setCustomInput({ ...customInput, customField: response?.data?.customInput?.customField })
      alert("Successfully Added Variable")

    } else if (selectedTemplate["TEMPLATE_HEADER_TYPE"] && customInput["url"]) {
      object = { customInput: { url: customInput["url"] } }
      await updateWaJob(jobId, object)

      setCustomAdded(!customeAdded)
      // setCustomInput({ ...customInput, url:response?.data?.customInput?.url })
      alert("Successfully Added Variable")

    } else {

      alert("Please Enter The Custom Values")
    }


  }

  const handleFindInValidNum = (jobData: any) => {
    const res = jobData.some((jobData: any) => !jobData.phoneNumber);
    return res;
  }

  const handleClearInValidNum = async () => {
    if (data.jobData.length) {
      const res = data.jobData.filter((jobData: any) => jobData.phoneNumber);
      // console.log('res:', res);
      const payload = {
        jobData: res
      }
      const getLatestData = await updateWrapper({ jobId, payload });
      console.log('getLatestData:', getLatestData);
      setData(getLatestData);
      setClearBtnStatus(false);
    }

  }
  // console.log('data:', data);

  const updateWrapper = async ({ jobId, payload }: any) => {
    if (jobId) {
      const res = await updateWaJob(jobId, payload);
      return res
    }
  }

  useEffect(() => {
    // alert("check")
    getJobByJobId(jobId)
      .then(async (res: any) => {
        console.log('res:', res);
        setData(res);
        console.log(res?.customInput)
        if (res?.customInput) {
          setCustomInput(res?.customInput)
        }
        if (res.templateName) {
          setPreSelectedTemplate(res.templateName);
        }
        if (res.jobData.length) {

          // console.log("jobData:", res.jobData);
          ///check the status if already send msg then send button should be disabled///
          const status = WaStatusChecker(res)
          setStatusCheck(status);
          const getBenData = await getManyBenData(res.jobData);
          console.log('getBenData:', getBenData);
          const clearBtnStatus = handleFindInValidNum(res.jobData);
          // console.log('clearBtnStatus:', clearBtnStatus);
          setClearBtnStatus(clearBtnStatus);
          ////if suppose we have jobData but inside we don't have benId so we need to handle it that why these condition///
          if (getBenData.length) {
            setBenData(getBenData);
            // console.log('res.templateName:', res.templateName)
            const templateVariable = await getVariable(res.templateName);
            // console.log('templateVariable:', templateVariable);
            // alert("check")
           
          
            const tableData = buildDynamicTableData({ benData: getBenData, templateVariable });
            const { header, benData } = tableData
            setTableDynamicData((prev: any) => {
              return {
                ...prev,
                header,
                benData
              }
            });
          }
          //now i want template variable for selected template and ben data///
          // after that i will make table with that data//
          // then send button build payload and call whatsapp send api///
        } else {
          // alert("check---1")
          setTableDynamicData({ header: [], benData: [] });
        }
      }).catch((e: any) => { alert(e?.response?.message || "Check"); console.log(e) });

  }, [jobId, benData?.length, data?.jobData?.length, customeAdded]);
  // console.log('tableDynamicData:', tableDynamicData);
  // const handleSelectTemp=()=>{
  //   setIsSelectTemp(!isSelectTemp);
  // }

  const handleCustomInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomInput({ ...customInput, [e.target.name]: e.target.value })
    sessionStorage.setItem("customInput", JSON.stringify({ ...customInput, [e.target.name]: e.target.value }));
  }
  // console.log("selected templet", selectedTemplate)
  // console.log('tableData:', tableDynamicData);
  return (
    <>
      <Layout>
        {data && <WaMessageDetails data={data} />}
        {data && statusCheck ? <div>
          <h1 className={style.templateName}>Template Name: {data.templateName || ""}</h1>

        </div> : (
          <>
            <label>Select Template</label>
            <Select
              w={"fit-content"}
              className={style.select_border}
              placeholder="Select Template"
              onChange={(e) => { handleCatchSelectedTemplate(e.target.value) }}
              value={preSelectedTemplate ? preSelectedTemplate : null}
            >
              {templateData && Object.entries(templateData).map(([key, value]) => (
                <option key={key} value={key}>{key}</option>
              ))}
            </Select>
          </>
        )}
        <Flex mt={"30px"} gap={"20px"}>
          {selectedTemplate["TEMPLATE_VARS"]?.includes("customField") &&
            < Box >
              <label >Custom Field: </label>
              <Input onChange={handleCustomInput} width={"fit-content"} type="text" name={"customField"} value={customInput["customField"]} placeholder="Custom Field"></Input>
            </Box>}
          {selectedTemplate["TEMPLATE_HEADER_TYPE"] && <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label>Custom URL: </label>
            <Input
              onChange={handleCustomInput}
              width="fit-content"
              type="text"
              name="url"
              value={customInput["url"]}
              placeholder="URL"
            />

          </Box>
          }
          {(selectedTemplate["TEMPLATE_VARS"]?.includes("customField") || selectedTemplate["TEMPLATE_HEADER_TYPE"]) && (<CustomButton
            label="Add Custom Variables"
            handleClick={addCustomUrl}
            additionalData=""
            loading={isLoading}
            customStyle={statusCheck ? style.disabled : style.customBtnStyle}
            disabled={statusCheck}
          />)}
        </Flex>
        {/* <label>Select Template</label>
          <CustomSelect
            label={data.templateName||"Template"}
            getOptionList={getTemplate}
            optionList={templateData}
            setCompanySelected={handleCatchSelectedTemplate}
          /> */}

        <div className={style.button_align}>
          {selectedTemplate !== "" || preSelectedTemplate !== "" ? (
            <CustomButton
              label="Select Ben"
              handleClick={handleFilterModal}
              additionalData={""}
              customStyle={statusCheck ? style.disabled : style.customBtnStyle}
              disabled={statusCheck}
            />
          ) : ""}
          {tableDynamicData.header.length > 0 && (
            <div>
              <CustomButton
                label="Send Wa Message"
                handleClick={handleSendMsg}
                additionalData={""}
                loading={isLoading}
                customStyle={statusCheck ? style.disabled : style.customBtnStyle}
                disabled={statusCheck}
                value={MessageTo.All}
              />
            </div>
          )}
          {tableDynamicData.header.length > 0 && (
            <div>
              <CustomButton
                label="Send To Subscriber"
                handleClick={handleSendMsg}
                additionalData={""}
                loading={isLoading}
                customStyle={statusCheck ? style.disabled : style.customBtnStyle}
                disabled={statusCheck}
                value={MessageTo.Subscriber}
              />
            </div>
          )}
          {tableDynamicData.header.length > 0 && (
            <div>
              <CustomButton
                label="Clear All Ben"
                handleClick={handelClearBen}
                additionalData={""}
                loading={isLoading}
                customStyle={statusCheck ? style.disabled : style.customBtnStyle}
                disabled={statusCheck}
              />
            </div>
          )}
          {clearBtnStatus && (
            <div>
              <CustomButton
                label="Clear All Invalid Ben"
                handleClick={handleClearInValidNum}
                additionalData={""}
                loading={isLoading}
                customStyle={statusCheck ? style.disabled : style.customBtnStyle}
                disabled={statusCheck}
              />
            </div>
          )}
        </div>
        {tableDynamicData.header.length > 0 && (
          <div>
            <DynamicTable handleClick={handleClickDynamicTable} headers={tableDynamicData.header} benData={tableDynamicData.benData} deleteComponent={deleteComponent} />
          </div>
        )}

        {benFilter && <BenFilterPopup handleCloseModal={() => handleCloseModal(setBenFilter, benFilter)} />}

      </Layout>
    </>
  );
};
