import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getJobByJobId, getManyBenData } from "../WaMessageDetails/Action/action";
import { getDeliveryStatus, updateDeliveryStatus } from "./Action/action";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { DynamicTable } from "../../GlobalComponent/CustomTable/DynamicTable";
import { CustomCard } from "../../GlobalComponent/Card/Card";
import { getTemplateByVariable, getVariable } from "../../Helper/waTemplateVariables";
import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import { handleBuildPayloadForWaMsg } from "../SendBulkWaMessage/Action/actions";
import style from "./wrapper.module.css";
import { IconButton, Skeleton, VStack, Text, HStack } from "@chakra-ui/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

export const WaResponseWrapper: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [data, setData] = useState<any>({
    headers: [],
    benData: []
  });
  const [cardData, setCardData] = useState<any>({});
  const [jobData, setJobData] = useState<any>([])
  const [isMsgFailed, setIsMsgFailed] = useState<boolean>(false);
  const [isStatusFetched,setIsStatusFetched]=useState<boolean>(false)
  const [failedMsg, setFailedMsg] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<any>();
  const [page, setpage] = useState<any>({
    current: 1,
    totalPage: 1
  })
  // console.log('jobId:', jobId);



  useEffect(() => {
  
    setIsLoading(true);
    getJobByJobId(jobId)
      .then(async (res: any) => {
        // console.log("res:", res);
        setJobData(res);
        if (!jobId) return alert("JobId NotFound create a job and try again");
        const deliveryData = await getDeliveryStatus(jobId,page.current);
     
        setCardData(deliveryData?.res?.deliveryCount);
        setpage((prev: any) => ({
          ...prev,
          totalPage: deliveryData?.totalPage,
        }));
        if (deliveryData?.res?.combinedData?.length) {
          let headers = Object.keys(deliveryData?.res?.combinedData[0]);
          // console.log('headers:', headers,deliveryData);
          setData({ headers, benData: deliveryData?.res?.combinedData });
          const getFailedMsgData = deliveryData?.res?.combinedData.filter((item: any) => item?.deliveryStatus?.toLowerCase() === 'failed' || item?.deliveryStatus?.toLowerCase() === 'in_progress' || item?.deliveryStatus?.toLowerCase() === 'accepted');
          setFailedMsg(getFailedMsgData);
          ////i got ben Data now i want templateName,variable,jobData////
          if (getFailedMsgData.length) {
            setIsMsgFailed(true);
          } else {
            setIsMsgFailed(false);
          };
          //if suppose any one message was failed then here we going to give option for resend button in the top once they clicked it wil again send
          //even it is failed???//but if it require any url or other data we dnt have 😧? need to discuss?
          //or we can give option to resend all failed messages
          //here need to find out by delivery status and need to update


        }
      })
      .catch((e: any) => {
        // alert(e?.response?.message ||e?.message|| "Some technical issue we are facing try again later");
        console.log(e);
      }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [jobId, isResend,page.current,isStatusFetched]);

  const resendMsg = async () => {
    //getVariable//
    const storedData = sessionStorage.getItem("customInput");
    let customInput;
    if (storedData !== null) {
      customInput = JSON.parse(storedData);
    } else {
      // If the item is not in sessionStorage, use a default value
      customInput = {}; // You can change this to your preferred default value
    }
    const getBenData = await getManyBenData(failedMsg);
    const templateVariable = await getVariable(jobData.templateName);
    const selectedTempDetails = await getTemplateByVariable({ serviceName: "api-whatsapp-service", templateName: jobData.templateName })
    const res = await handleBuildPayloadForWaMsg({ benData: getBenData, templateName: jobData?.templateName, templateVariable: templateVariable, setIsLoading, isLoading, jobData: jobData.jobData, jobId, customInput, btnType: "Resend", templateData: selectedTempDetails?.data });
    setIsResend(res)

  }

  const handlePage = (page: string) => {
    setpage((prev: any) => ({
      ...prev,
      current: Number(prev.current) + (page === "increase" ? 1 : -1),
    }));
  };

  const fetchDeliveryStatus=async()=>{
    try{
      if(jobId){
        setIsLoading(true);
        let response=await updateDeliveryStatus(jobId)
     
        if(response?.status===200){
          // alert(response?.data?.message)
          setIsStatusFetched(!isStatusFetched)

        }else{
          setIsLoading(false)
          alert("Something went wrong")
        }
      }
      
    }catch(err){
      alert(err)
    }

  }


  return (
    <Layout>
      <div>
        <h1>Whatsapp ResponseDetails-{jobData?.jobName || "jobName Not Found"}</h1>

      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px", width: "20%", marginLeft: "1rem" }}>
        {isLoading ? (
          <Skeleton height="120px" width="100%" borderRadius="10px" />
        ) : (
          cardData && <CustomCard data={cardData} />
        )}
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px", width: "30%", marginLeft: "1rem",display:"flex" }}>
        {isMsgFailed && <CustomButton handleClick={resendMsg} label="Resend All Failed Messages" additionalData="" customStyle={style.button} />}
        <CustomButton handleClick={fetchDeliveryStatus} label="Get deliveryStatus" additionalData="" customStyle={style.button}/>
      </div>
      <div>
        {isLoading ? (
          <VStack spacing={4} align="stretch">
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} height="40px" width="100%" borderRadius="5px" />
            ))}
          </VStack>
        ) : (
          <DynamicTable headers={[...data?.headers?.filter((header:any) => header !== "deliveryStatus"), 
            "deliveryStatus"]} benData={data?.benData} handleClick={() => { }} />
        )}
   
          {
            page?.totalPage>1&&<HStack mt={10} pb={30} pr={10} justifyContent="flex-end">
            <IconButton
              colorScheme="green"
              aria-label="Go to previous page"
              icon={<GrFormPrevious />}
              onClick={()=>handlePage("decrease")}
              isDisabled={page.current===1}
            />
            <Text>
              {page.current}/{page.totalPage}
            </Text>
            <IconButton
              colorScheme="green"
              aria-label="Go to next page"
              icon={<GrFormNext />}
              onClick={()=>handlePage("increase")}
              isDisabled={page.current===page.totalPage}
            />
          </HStack>
          }
      

      </div>

    </Layout>
  );
};
