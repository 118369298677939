import { updateWhatsappMsgDeliveryStatus, whatsappMsgDeliveryStatus } from "../../../Http/Index";

export const getDeliveryStatus = async (waId: string,page:number) => {
  try {
    const response = await whatsappMsgDeliveryStatus(waId,page);
    return response?.data?.data;
  } catch (err) {
    throw err;
  }
}

export const updateDeliveryStatus = async (waId: string) => {
  try {
    const response = await updateWhatsappMsgDeliveryStatus(waId);
    return response?.data;
  } catch (err) {
    throw err;
  }
}



