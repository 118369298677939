import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert";
import {
  createWaJob,
  getAllWaJob,
  replaceBenWithSubscriber,
  sendBulk,
  updateWaJobById,
} from "../../../Http/Index";
import { WaStatusChecker } from "../../WaMessageDetails/Action/action";
import { MessageTo } from "../../WaMessageDetails/WrapperWaMessage";


// const templateFormate: any = {
//   beneficiary: "benName",
// };

export const getSelectedTemplateDetails = ({
  value,
  setSelectedTemplate,
  templateData,
  jobId,
}: any) => {
  // console.log('selectedTemplate:', value,templateData);
  templateData &&
    Object.entries(templateData)?.map(async (template: any) => {
      if (template[0] === value) {
        // console.log(template[0]);
        const payload = {
          templateName: template[0],
          templateVar: template[1].TEMPLATE_VARS,
        };
        await updateWaJob(jobId, payload);
        return setSelectedTemplate(template[1]);
      }
    });
};

export const handleCatchSelectedTemplate = (selectedTemplate: any) => {
  // console.log(selectedTemplate);
};

export const handleBuildPayloadForWaMsg = async ({
  setIsLoading,
  jobId,
  messageTo,
  jobData
}: any) => {
  // console.log('templateName:',benData);



  if (messageTo === MessageTo.Subscriber) {
    try {
      await replaceBenWithSubscriber(jobId)
    } catch (error: any) {
      console.log(error)
      alert(error?.response?.data?.message || error)
      return
    }
  }


  for (let jobFields of jobData) {
    let mobileNumber = jobFields?.phoneNumber ? jobFields?.phoneNumber.toString() : null
    const mobileRegex = /^[6-9]\d{9}$/;

    if (!mobileNumber || !mobileRegex.test(mobileNumber)) {
      alert(`Invalid Number For Ben ${jobFields.benId}`)
      return
    }
  }

  let payload = {
    jobId: jobId,
  }

  try {
    setIsLoading(true);
    const res = await sendBulk(payload);
    setIsLoading(false);
    ShowAlert("success", `Message sent to all beneficiary`);
    return res
  } catch (e: any) {
    console.log('e:', e);
    alert(
      e?.response?.data?.message || "some technical issue in sending wa message"
    );
    setIsLoading(false);
  }
};

export const handleCreateJobBtnClick = ({
  setIsModalOpen,
  isModalOpen,
}: any) => {
  setIsModalOpen(!isModalOpen);
};

export const separateBenDataForTable = ({ data, setTableData }: any) => {
  // console.log('data:', data);
  let headers: any = [];
  let formattedData: any = [];
  data.forEach((benData: any) => {
    headers = Object.keys(benData);
    const status = WaStatusChecker(benData);
    formattedData.push({
      ...benData,
      agentData: benData?.agentData?.agentName,
      status: status ? "send" : "pending",
    });
  });
  // console.log('data:', data);
  const mandatoryFields = ["jobName", "createdAt", "updatedAt", "agentData"];
  const formateHeader = findAndReplaceArr({
    arr: headers,
    findValue: "agentData",
    replace: "agentName",
    mandatoryFields,
  });
  setTableData({ headers: formateHeader, benData: formattedData });
};

const findAndReplaceArr = ({
  arr,
  findValue,
  replace,
  mandatoryFields,
}: any) => {
  // console.log('arrss:', arr)
  for (let i = 0; i < arr.length; i++) {
    // console.log("before",arr[i],i)
    if (!mandatoryFields.includes(arr[i])) {
      arr.splice(i, 1);
    }
    //  console.log("arr-after",arr[i],i)
  }
  arr.push("status");
  return arr;
};

export const getAllWaJobs = async () => {
  try {
    const res = await getAllWaJob();
    return res.data.data.reverse();
  } catch (e) {
    throw e;
  }
};

export const handleCreateJob = async ({
  catchInput,
  handleCloseModal,
  updateList,
}: any) => {
  if (!catchInput) {
    alert("Please Enter Job Name");
    return;
  }
  // const getAgentDetails = JSON.parse(localStorage.getItem("agentData") || "");
  const payload = buildPayload({
    jobName: catchInput,

  });
  await createJob({ payload });
  updateList(); ///this will refetch the jobList again after job updated///
  handleCloseModal();
};

const buildPayload = ({ jobName }: any) => {
  return {
    jobName,

  };
};

const createJob = async ({ payload }: any) => {
  try {
    const res = await createWaJob(payload);
    return res;
  } catch (e: any) {
    alert(
      e?.response?.data?.message || "some technical issue in creating Wa job"
    );
  }
};

export const updateWaJob = async (jobId: string | any, payload: any) => {
  
  try {
    const res = await updateWaJobById(jobId, payload);
   
    return res;
  } catch (e: any) {
    alert(
      e?.response?.data?.message || "some technical issue in creating Wa job"
    );
  }
};
